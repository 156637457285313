import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';

const ContactDataViewer = () => {
  const [contactDataList, setContactDataList] = useState([]);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get('https://merchant.lillian-ai.com/api/contact-data');
        setContactDataList(response.data);
      } catch (error) {
        console.error('無法獲取資料:', error);
      }
    };

    fetchContactData();
  }, []);

  const handleCheckboxChange = async (id, checked) => {
    try {
      await axios.put(`/api/contact-data/${id}`, { contacted: checked });
      setContactDataList(prevData => 
        prevData.map(data => data._id === id ? { ...data, contacted: checked } : data)
      );
    } catch (error) {
      console.error('無法更新已聯繫狀態:', error);
    }
  };

  // 使用 debounce 包裝 handleNoteChange
  const handleNoteChange = useCallback(debounce(async (id, note) => {
    try {
      await axios.put(`http://merchant.lillian-ai.com/api/contact-data/${id}`, { note });
      setContactDataList(prevData => 
        prevData.map(data => data._id === id ? { ...data, note } : data)
      );
    } catch (error) {
      console.error('無法更新備註:', error);
    }
  }, 500), []);

  const handleNoteInputChange = (id, value) => {
    setContactDataList(prevData => 
      prevData.map(data => data._id === id ? { ...data, note: value } : data)
    );
    handleNoteChange(id, value);
  };

  return (
    <div className="data-viewer">
      <h2>聯繫資料檢視</h2>
      <table>
        <thead>
          <tr>
            <th>商家名稱</th>
            <th>電子郵件</th>
            <th>電話號碼</th>
            <th>已聯繫</th>
            <th>備註</th>
          </tr>
        </thead>
        <tbody>
          {contactDataList.map(data => (
            <tr key={data._id}>
              <td>{data.name}</td>
              <td>{data.email}</td>
              <td>{data.phone}</td>
              <td>
                <input 
                  type="checkbox" 
                  checked={data.contacted || false} 
                  onChange={(e) => handleCheckboxChange(data._id, e.target.checked)} 
                />
              </td>
              <td>
                <input 
                  type="text" 
                  value={data.note || ''} 
                  onChange={(e) => handleNoteInputChange(data._id, e.target.value)} 
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactDataViewer;
