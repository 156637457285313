import React from 'react';
import './Section.css';

const Section = ({ id, title, content, backgroundImage, backgroundColor, special, halfHeight, buttonText, buttonLink, leftContent, rightContent }) => {
  const sectionStyle = special 
    ? { backgroundColor, padding: '20px', boxSizing: 'border-box' } 
    : backgroundImage 
    ? { backgroundImage: `url(${backgroundImage})` } 
    : { backgroundColor };

  return (
    <div id={id} className={`section ${halfHeight ? 'half-height' : ''} ${special ? 'special-section' : ''}`} style={sectionStyle}>
      <div className="section-inner" style={special ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '100%' } : {}}>
        <div className="section-content">
          {leftContent && rightContent ? (
            <>
              <div className="left-content">
                {leftContent}
              </div>
              <div className="right-content">
                {rightContent}
              </div>
            </>
          ) : (
            <>
              <h2>{title}</h2>
              <div className="section-paragraphs">
                {content.map((paragraph, index) => (
                  <div key={index} className="section-paragraph">
                    <h3>{paragraph.heading}</h3>
                    {typeof paragraph.text === 'string' ? (
                      paragraph.text.includes('<br>') ? (
                        <p dangerouslySetInnerHTML={{ __html: paragraph.text }}></p>
                      ) : (
                        <p>{paragraph.text}</p>
                      )
                    ) : (
                      <div>{paragraph.text}</div>
                    )}
                  </div>
                ))}
              </div>
              {buttonText && (
                <div className="section-button">
                  <a href={buttonLink} className="button">{buttonText}</a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section;
