import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Section from './components/Section';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import OnlineApplication from './components/OnlineApplication';
import DataViewer from './components/DataViewer';
import ContactDataViewer from './components/ContactDataViewer';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
      <Section 
        id="intro"
        title="成為 UberEats 合作夥伴"
        content={[
          { heading: "", text: "Uber 的全球系統提供您所需的彈性、能見度和顧客深入分析，助您與更多顧客建立聯繫。立即成為合作夥伴。" }
        ]}
        backgroundImage="/intro-bg.jpg"
        buttonText="立即線上申請"
        buttonLink="/apply" 
      />
      <Section 
        id="contact"
        title="請專人與我聯繫"
        leftContent={<ContactForm />}
        rightContent={
          <div>
          <h2>請專人與我聯繫</h2>
          <p>Uber 的全球系統提供您所需的彈性、能見度和顧客深入分析，助您與更多顧客建立聯繫，立即成為合作夥伴。</p>
        </div>
        }
        backgroundImage="/contact-bg.jpg"
        halfHeight={true}
        special={true} 
        
      />
      <Section 
        id="why-ubereats"
        title="為什麼選擇UberEats?"
        content={[
          { heading: "自行決定外送方式", text: "我們提供靈活彈性的服務，您可依需求加以客製化。自行安排外送人員，或由 Uber 平台協助配對外送合作夥伴。" },
          { heading: "提高知名度", text: "透過 App 內行銷活動觸及更多顧客、提升銷售額，在眾多競爭對手中脫穎而出。" },
          { heading: "與顧客建立聯繫", text: "藉由參考具體可行的資料深入分析、回應評論或提供常客獎勵方案，將顧客變成常客。" }
        ]}
        backgroundImage="/why-ubereats-bg.jpg"
      />
      <Section 
        id="how-to-use"
        title="合作商家如何運用 Uber Eats 提供外送"
        content={[
          { heading: "顧客訂餐", text: "讓顧客透過 Uber Eats App 找到您的餐廳並下訂單。" },
          { heading: "餐廳備餐", text: "貴餐廳接受訂單並準備餐點。" },
          { heading: "外送合作夥伴抵達", text: "使用 Uber 平台的外送合作夥伴會前往貴餐廳取單，外送到顧客手上。" },
        ]}
        backgroundImage="/how-to-use-bg.jpg"
        halfHeight={true} // 將高度減半
      />
      <Section 
        id="three-steps"
        title="只要簡單三步驟，即可開始"
        content={[
          { heading: "", text: "1. 請提供貴餐廳的資訊及上傳所需檔案。 <br>2. 專人聯繫您確認資訊。 <br>3. 收到平板上線營業！" }
        ]}
        backgroundImage="/three-steps-bg.jpg"
      />
      <Section 
        id="recommendations"
        title="商家推薦"
        content={[
          { heading: "", text: "「Uber Eats 協助我們提高品牌知名度，就算在平時不會接觸到我們餐廳的社區，都能打響名號。」<br> - Diana Yin 洛杉磯 Poppy + Rose 老闆" }
        ]}
        backgroundColor="#539E59"
        halfHeight={true} // 將高度減半
      />
      <Section 
        id="faq"
        title="有任何問題嗎？讓我們為您解答"
        content={[
          { heading: "申辦費用多少？", text: "目前申辦完全免費，還送免費試用期！" },
          { heading: "有綁約嗎？", text: "我們的服務，沒有綁約、沒有違約金，隨時想停用可以直接跟我們說。" },
          { heading: "申請時間要多久？", text: "線上申請後一個工作天內會有專人與您聯繫，最快五個工作天上線。" },
        ]}
        backgroundImage="/faq-bg.jpg"
        halfHeight={true} 
        buttonText="立即線上申請"
        buttonLink="/apply" 
      />
      <Footer />
      </>
          } />
          <Route path="/apply" element={<OnlineApplication />} />
          <Route path="/data-viewer" element={<ProtectedRoute element={DataViewer} />} />
          <Route path="/contact-data-viewer" element={<ProtectedRoute element={ContactDataViewer} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
