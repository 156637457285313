import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src="/ubereats_logo.png" alt="Uber Eats Logo" className="logo" />
        </Link>
        <h1>Uber Eats for Merchants</h1>
      </div>
      <div className="navbar-links">
        <a href="https://lin.ee/G63gPcL" target="_blank" rel="noopener noreferrer" className="line-customer-service">
          官方Line客服
        </a>
        <Link to="/apply" className="apply-button">立即線上申請</Link>
      </div>
    </nav>
  );
};

export default Navbar;
