import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component }) => {
  const [inputPassword, setInputPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const correctPassword = 'Jean9061';

  const handlePasswordChange = (e) => {
    setInputPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputPassword === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('密碼錯誤');
    }
  };

  if (isAuthenticated) {
    return <Component />;
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>請輸入密碼</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={inputPassword}
          onChange={handlePasswordChange}
          placeholder="輸入密碼"
          style={{ padding: '10px', fontSize: '16px' }}
        />
        <button type="submit" style={{ padding: '10px 20px', fontSize: '16px', marginLeft: '10px' }}>
          確認
        </button>
      </form>
    </div>
  );
};

export default ProtectedRoute;
