import React, { useState } from 'react';
import axios from 'axios';
import './OnlineApplication.css';

const OnlineApplication = () => {
  const [formData, setFormData] = useState({
    restaurantName: "",
    ownerName: "",
    ownerId: "",
    contactPhone: "",
    contactEmail: "",
    companyName: "",
    companyId: "",
    foodLicenseNumber: "",
    hasInvoice: "",
    restaurantAddress: "",
    restaurantPhone: "",
    restDay: "",
    supplyHours: "",
    preparationTime: "",
    meatOrigin: "",
    bank: "",
    tabletAddress: "",
    idCard: null,
    accountBook: null,
    businessLicense: null,
    menu: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      // const response = await axios.post("http://localhost:5001/api/apply", formDataToSend, {
      const response = await axios.post("https://merchant.lillian-ai.com/api/apply", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.status === 200) {
        alert("申請成功");
      }
    } catch (error) {
      alert("申請失敗");
    }
  };

  return (
    <div className="online-application">
      <h2>UberEATS 合作店家資料</h2>
      <form onSubmit={handleSubmit}>
        <label>
          上線餐廳名稱：
          <input type="text" name="restaurantName" value={formData.restaurantName} onChange={handleChange} required />
        </label>
        <h3>負責人</h3>
        <label>
          負責人姓名：
          <input type="text" name="ownerName" value={formData.ownerName} onChange={handleChange} required />
        </label>
        <label>
          負責人身分證字號：
          <input type="text" name="ownerId" value={formData.ownerId} onChange={handleChange}  />
        </label>
        <h3>聯絡人</h3>
        <label>
          手機：
          <input type="text" name="contactPhone" value={formData.contactPhone} onChange={handleChange}  />
        </label>
        <label>
          Email信箱：
          <input type="email" name="contactEmail" value={formData.contactEmail} onChange={handleChange}  />
        </label>
        <h3>營業資訊</h3>
        <label>
          營登公司名：
          <input type="text" name="companyName" value={formData.companyName} onChange={handleChange}  />
        </label>
        <label>
          統編：
          <input type="text" name="companyId" value={formData.companyId} onChange={handleChange}  />
        </label>
        <label>
          食品登錄字號:
          <input type="text" name="foodLicenseNumber" value={formData.foodLicenseNumber} onChange={handleChange}  />
        </label>
        <label>
          有無開立統一發票：
          <input type="text" name="hasInvoice" value={formData.hasInvoice} onChange={handleChange}  />
        </label>
        <label>
          餐廳地址：
          <input type="text" name="restaurantAddress" value={formData.restaurantAddress} onChange={handleChange}  />
        </label>
        <label>
          餐廳店內電話：
          <input type="text" name="restaurantPhone" value={formData.restaurantPhone} onChange={handleChange}  />
        </label>
        <label>
          公休日：
          <input type="text" name="restDay" value={formData.restDay} onChange={handleChange}  />
        </label>
        <label>
          餐廳供應時段(接單時間)：
          <input type="text" name="supplyHours" value={formData.supplyHours} onChange={handleChange}  />
        </label>
        <label>
          備餐時間（例如：10分鐘）：
          <input type="text" name="preparationTime" value={formData.preparationTime} onChange={handleChange}  />
        </label>
        <label>
          豬肉/牛肉產地（例如:豬肉台灣，沒有則填：無）:
          <input type="text" name="meatOrigin" value={formData.meatOrigin} onChange={handleChange}  />
        </label>
        <label>
          配合銀行：
          <input type="text" name="bank" value={formData.bank} onChange={handleChange}  />
        </label>
        <label>
          平板收件地址：（如同店內地址則不需填寫）
          <input type="text" name="tabletAddress" value={formData.tabletAddress} onChange={handleChange} />
        </label>
        <h3>上傳文件</h3>
        <label>
          負責人身分證 正反面:
          <input type="file" name="idCard" onChange={handleFileChange}  />
        </label>
        <label>
          收款帳號 存摺封面:
          <input type="file" name="accountBook" onChange={handleFileChange}  />
        </label>
        <label>
          商業登記抄本(或公司變更登記表):
          <input type="file" name="businessLicense" onChange={handleFileChange}  />
        </label>
        <label>
          店內菜單(包含價格及需要客製化的部分):
          <input type="file" name="menu" onChange={handleFileChange}  />
        </label>
        <button type="submit">提交</button>
      </form>
    </div>
  );
};

export default OnlineApplication;
