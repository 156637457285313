import React, { useState } from "react";
import axios from "axios";
import './ContactForm.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // const response = await axios.post("http://localhost:5001/api/register", formData, {
      const response = await axios.post("https://merchant.lillian-ai.com/api/register", formData, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.status === 201) {
        alert("註冊成功");
      }
    } catch (error) {
      alert("註冊失敗");
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <label>
        商家名稱：
        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
      </label>
      <label>
        營業地址：
        <input type="text" name="address" value={formData.address} onChange={handleChange} required />
      </label>
      <label>
        電子郵件：
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
      </label>
      <label>
        電話號碼：
        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
      </label>
      <button type="submit">提交</button>
    </form>
  );
}

export default ContactForm;
