import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DataViewer = () => {
  const [dataList, setDataList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://merchant.lillian-ai.com/api/all');
        setDataList(response.data);
        setSelectedData(response.data[0]);
      } catch (error) {
        console.error('無法獲取資料:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    const selectedId = event.target.value;
    const data = dataList.find(d => d._id === selectedId);
    setSelectedData(data);
  };

  const renderImage = (id, field) => {
    if (!id || !field) return null;
    return (
      <img
        src={`/api/file/${id}/${field}`}
        alt={field}
        style={{ width: '200px', height: 'auto' }}
      />
    );
  };

  return (
    <div className="data-viewer">
      <h2>資料檢視</h2>
      <select onChange={handleChange}>
        {dataList.map(data => (
          <option key={data._id} value={data._id}>
            {data.restaurantName} - {data.ownerName}
          </option>
        ))}
      </select>
      {selectedData && (
        <div className="data-details">
          <h3>餐廳名稱: {selectedData.restaurantName}</h3>
          <p>負責人姓名: {selectedData.ownerName}</p>
          <p>負責人身分證字號: {selectedData.ownerId}</p>
          <p>聯絡電話: {selectedData.contactPhone}</p>
          <p>聯絡信箱: {selectedData.contactEmail}</p>
          <p>營登公司名: {selectedData.companyName}</p>
          <p>統編: {selectedData.companyId}</p>
          <p>食品登錄字號: {selectedData.foodLicenseNumber}</p>
          <p>有無開立統一發票: {selectedData.hasInvoice}</p>
          <p>餐廳地址: {selectedData.restaurantAddress}</p>
          <p>餐廳電話: {selectedData.restaurantPhone}</p>
          <p>公休日: {selectedData.restDay}</p>
          <p>餐廳供應時段: {selectedData.supplyHours}</p>
          <p>備餐時間: {selectedData.preparationTime}</p>
          <p>豬肉/牛肉產地: {selectedData.meatOrigin}</p>
          <p>配合銀行: {selectedData.bank}</p>
          <p>平板收件地址: {selectedData.tabletAddress}</p>
          <div>
            <h4>負責人身分證正反面:</h4>
            {renderImage(selectedData._id, 'idCard')}
          </div>
          <div>
            <h4>收款帳號存摺封面:</h4>
            {renderImage(selectedData._id, 'accountBook')}
          </div>
          <div>
            <h4>商業登記抄本(或公司變更登記表):</h4>
            {renderImage(selectedData._id, 'businessLicense')}
          </div>
          <div>
            <h4>店內菜單(包含價格及需要客製化的部分):</h4>
            {renderImage(selectedData._id, 'menu')}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataViewer;
